import request from '@/utils/request';

/**
 * 获取所有的列表
 * @param {Object} data
 */
export function getAllList(data) {
  return request({
    url: '/psychology/appointment/getAllList',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 发送通知
 */
export function sendMessage(data) {
  return request({
    url: '/psychology/appointment/sendMessage',
    method: 'post',
    data
  });
}

/**
 * @param {Object} 修改状态
 */
export function updateStatus(data) {
  return request({
    url: '/psychology/appointment/updateStatus',
    method: 'post',
    data
  });
}

/**
 * 删除操作
 */
export function delAppointment(data) {
  return request({
    url: '/psychology/appointment/del',
    method: 'post',
    data
  });
}

/**
 * 发送短信通知（局长座谈时间）
 * @param {Object} data
 */
export function sendTimeMsg(data) {
  return request({
    url: '/psychology/appointment/sendTimeMsg',
    method: 'post',
    data
  });
}
//领导
export function getLeaderList(data) {
  return request({
    url: '/psychologyLeader',
    method: 'get',
    data
  });
}
export function createLeader(data) {
  return request({
    url: '/psychologyLeader/create',
    method: 'post',
    data
  });
}
export function removeLeader(data) {
  return request({
    url: '/psychologyLeader/remove',
    method: 'post',
    data
  });
}
export function updateLeader(data) {
  return request({
    url: '/psychologyLeader/update',
    method: 'post',
    data
  });
}
