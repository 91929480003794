import request from '@/utils/request';

/**保存或修改数据字典信息
 * @param {Object} data
 */
export function saveDict(data) {
  return request({
    url: '/dict/save',
    method: 'post',
    data
  });
}

/**查询数据字典列表
 * @param {Object} data
 */
export function getDictList(data) {
  return request({
    url: '/dict/getList',
    method: 'post',
    data
  });
}

/**删除数据字典
 * @param {Object} data
 */
export function delDict(data) {
  return request({
    url: '/dict/del',
    method: 'post',
    data
  });
}

/**字典分类
 * @param {Object} data
 */
export function getGroupNames() {
  return request({
    url: '/dict/getGroupNames',
    method: 'post'
  });
}

/**修改字典类别名称
 * @param {Object} data
 */
export function updateDictName(data) {
  return request({
    url: '/dict/updateDictName',
    method: 'post',
    data
  });
}
